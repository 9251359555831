import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import DownloadBadges from "./DownloadBadges";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage, useIntl } from "react-intl";

const CTA = () => {
  const intl = useIntl();
  const { womanThumbsUp } = useStaticQuery(
    graphql`
      query {
        womanThumbsUp: file(relativePath: { eq: "woman-thumbs-up.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-blue-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  <FormattedMessage id="CTA_TITLE_1" />
                </span>
                <span className="block">
                  <FormattedMessage id="CTA_TITLE_2" />
                </span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-blue-200">
                <FormattedMessage id="CTA_DESCRIPTION" />
              </p>
              <DownloadBadges />
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <GatsbyImage
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              image={womanThumbsUp.childImageSharp.gatsbyImageData}
              alt={intl.formatMessage({ id: "CTA_IMAGE_ALT" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
