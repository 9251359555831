import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

interface Props {
  title: string;
  paragraphs: string[];
  image: IGatsbyImageData;
  imageAlt: string;
  isImageLeft: boolean;
}

const Explainer: React.FC<Props> = ({
  title,
  paragraphs,
  image,
  imageAlt,
  isImageLeft,
}) => {
  if (isImageLeft) {
    return (
      <div className="relative bg-white pt-16 lg:pt-32 pb-8 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {title}
                </h2>
                {paragraphs.map((paragraph, index) => (
                  <p key={index} className="mt-4 text-lg text-gray-500">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1 max-w-xl mx-auto lg:max-w-none lg:mx-0">
              <div className="lg:relative lg:h-full">
                <div className="px-4 sm:px-6 lg:px-0">
                  <GatsbyImage
                    imgClassName="rounded-xl"
                    image={image}
                    alt={imageAlt}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative bg-white pt-16 lg:pt-32 pb-8 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {title}
                </h2>
                {paragraphs.map((paragraph, index) => (
                  <p key={index} className="mt-4 text-lg text-gray-500">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 max-w-xl mx-auto lg:max-w-none lg:mx-0">
              <div className="lg:relative lg:h-full">
                <div className="px-4 sm:px-6 lg:px-0 max-w-sm">
                  <GatsbyImage
                    imgClassName="rounded-xl"
                    image={image}
                    alt={imageAlt}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Explainer;
